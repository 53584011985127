<script lang="ts">
  import { Route, Router } from 'svelte-navigator'
  import { ThemeProvider, Text, cn } from '@deta/ui'

  import { AMPLIFY } from '@/config'
  import { Auth } from '@aws-amplify/auth'

  import Lazy from './components/Lazy.svelte'

  const RequestResetPassword = () =>
    import('./pages/RequestResetPassword.svelte')
  const ResetPassword = () => import('./pages/ResetPassword.svelte')
  const SignIn = () => import('./pages/SignIn.svelte')
  const SignUp = () => import('./pages/SignUp.svelte')
  const UserConfirmation = () => import('./pages/UserConfirmation.svelte')

  Auth.configure(AMPLIFY.PRODUCTION)
</script>

<ThemeProvider>
  <div
    class={cn(
      'relative h-screen w-screen overflow-x-hidden flex justify-center items-center',
      'bg-seashell-25 dark:bg-seashell-900',
      'p-4 py-4 xs:py-16 lg:py-4'
    )}
  >
    <div class="m-auto">
      <Router primary={false}>
        <Route path="/login">
          <Lazy component={SignIn} />
        </Route>
        <Route path="/signup">
          <Lazy component={SignUp} />
        </Route>
        <Route path="/reset_password">
          <Lazy component={ResetPassword} />
        </Route>
        <Route path="/forgot_password">
          <Lazy component={RequestResetPassword} />
        </Route>
        <Route path="/user_confirmation">
          <Lazy component={UserConfirmation} />
        </Route>
        <Route>
          <Text>Page not found.</Text>
        </Route>
      </Router>
    </div>
  </div>
</ThemeProvider>
