// API endpoints
export const ENDPOINTS = {
  LOGIN: import.meta.env.VITE_API_ENDPOINT || '/api/v0/auth/login',
  SIGNUP: 'https://deta.space/api/v0/auth/signup',
}

// AWS Amplify configs
export const AMPLIFY = {
  // Not used for now
  // LOCAL: {
  //   aws_project_region: 'us-east-1',
  //   aws_cognito_identity_pool_id:
  //     'us-east-1:b0201476-09d1-4e75-be69-f14900e16c66',
  //   aws_cognito_region: 'us-east-1',
  //   aws_user_pools_id: 'us-east-1_8Oec7FZSn',
  //   aws_user_pools_web_client_id: '34scqm9lpss98k3sd30bdof7q6',
  // },
  PRODUCTION: {
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_VaHh0EoX5',
    aws_user_pools_web_client_id: '4io3aeqf1h967ufalk5n742cfj',
  },
}

// Username helper message
export const USERNAME_HELPER = `
· Username must be all lowercase <br>
· Username must be at least 4 characters <br>
· Username must be alphanumeric with optional dashes
`

// Password helper message
export const PASSWORD_HELPER = `
Password must be at least 8 characters and include: <br>
· One number <br>
· One special character <br>
· One uppercase and one lowercase letter
`
