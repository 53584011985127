<script lang="ts">
  import { onMount, SvelteComponent } from 'svelte'

  export let component: () => Promise<typeof SvelteComponent | any>

  let loadedComponent: typeof SvelteComponent | null = null

  onMount(() => {
    component().then(module => {
      loadedComponent = module.default
    })
  })
</script>

{#if loadedComponent}
  <svelte:component this={loadedComponent} {...$$props} />
{:else}
  <slot />
{/if}
