import '@deta/ui/output.css'
import './app.scss'

import App from './App.svelte'

import * as Sentry from '@sentry/svelte'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://c4afcf0c3db8476eadbf91831b65878f@o371570.ingest.sentry.io/4504713504489472',
  integrations: [new BrowserTracing()],
  tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.2,
})

const app = new App({
  target: document.getElementById('app') as HTMLDivElement,
})

export default app
